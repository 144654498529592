import axios from "axios";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { BASE_URL, demotableName, personsinfoTableName } from "../../config";

export const getUserFilters = async () => {
  const userId = jwtDecode(Cookies.get("ElectionToken")).id;
  try {
    const response = await axios.get(
      `${BASE_URL}/api/userFilters/${userId}`,

      {
        headers: {
          Authorization: `Bearer ${Cookies.get("ElectionToken")}`,
        },
      }
    );
    const filtersArray = response.data.filters;
    const filteredFilters = filtersArray.filter((filter) => {
      try {
        const filterDetails = JSON.parse(filter.filters);
        const role = getRoleFromToken();
        const tableName =
          role === "Demo_general" || role === "Demo"
            ? demotableName
            : personsinfoTableName;
        return filterDetails.tableName === tableName;
      } catch (err) {
        console.error("Error parsing filters:", err);
        return false;
      }
    });
    return filteredFilters;
  } catch (error) {
    throw new Error("Couldn't get user filters");
  }
};

export const saveFilter = async (filterName, finallSearchData) => {
  const saveFilterData = {
    userId: jwtDecode(Cookies.get("ElectionToken")).id,
    filterName: filterName,
    filters: finallSearchData,
  };

  try {
    const response = await axios.post(
      `${BASE_URL}/api/addfilter`,
      saveFilterData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("ElectionToken")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

export const getRoleFromToken = () => {
  const token = Cookies.get("ElectionToken");
  if (token) {
    const decodedToken = jwtDecode(token);
    return decodedToken.role; // Assuming the role is stored in the 'role' field
  }
  return null;
};

export const performSearch = async (filteredData) => {
  const url = `${BASE_URL}/api/search`;
  const role = getRoleFromToken();

  if (role === "Demo_general" || role === "Demo") {
    filteredData.tableName = demotableName;
  }
  try {
    const response = await axios.post(url, filteredData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("ElectionToken")}`,
      },
    });
    if (!response.data || Object.keys(response.data).length === 0) {
      throw new Error("No data available from the search.");
    }
    if (response.data.error) {
      throw new Error("No data available from the search.");
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchingResultsForAutoComplete = async (value, column) => {
  const url = `${BASE_URL}/api/addressresults`;
  //const url ="https://development-election-data-app.vantiqa.com/api/addressresults";

  const body = {
    value: value,
    column: column,
  };

  try {
    const response = await axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${Cookies.get("ElectionToken")}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteSavedFormat = async (filterId) => {
  const token = Cookies.get("ElectionToken");

  const response = await axios.delete(
    //`https://development-election-data-app.vantiqa.com/api/deletefilter/${filterId}`,
    `${BASE_URL}/api/deletefilter/${filterId}`,

    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data.message;
};
