import React from "react";
import { getRoleFromToken } from "../../services/searchPageApis/api";

const LookerReport = () => {
  const role = getRoleFromToken();
  const iframeSrc =
    role === "Demo"
      ? "https://lookerstudio.google.com/embed/reporting/478c48da-8ce6-4826-a55c-09301d46e74c/page/p_vuul3y47cd"
      : "https://lookerstudio.google.com/embed/reporting/6126c33f-c15b-46f4-a9b4-c31cf0f0dccf/page/p_vuul3y47cd";
  return (
    <>
      <div className="container-fluid mt-3" style={{ height: "130vh" }}>
        <iframe
          width="100%"
          height="100%"
          src={iframeSrc}
          frameBorder="10"
          style={{
            border: "3px solid #ccc",
            borderRadius: "10px",
          }}
          allowFullScreen
          title="Dashboard"
        ></iframe>
      </div>
    </>
  );
};
export default LookerReport;
