import React from "react";
import { getRoleFromToken } from "../../services/searchPageApis/api";

const PastElectionsReport = () => {
  const role = getRoleFromToken();
  const iframeSrc =
    role === "Demo"
      ? "https://lookerstudio.google.com/embed/reporting/4a45b55d-4c80-42e5-8fed-6351c956fa9c/page/kyhkD"
      : "https://lookerstudio.google.com/embed/reporting/ba29d9a2-15e2-4b91-8c19-fa8d1dd14d34/page/kyhkD";
  return (
    <>
      <div className="container-fluid mt-3" style={{ height: "130vh" }}>
        <iframe
          width="100%"
          height="100%"
          src={iframeSrc}
          frameBorder="10"
          style={{
            border: "3px solid #ccc",
            borderRadius: "10px",
          }}
          allowFullScreen
          title="Dashboard"
        ></iframe>
      </div>
    </>
  );
};
export default PastElectionsReport;
